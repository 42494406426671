import { SmartButtonStub, SmartButtonLink } from 'ui/smart-buttons'
import { Formatter, Spinner } from 'shared'
import { ParkApi } from 'api'
import { durationLabel } from './ParkReservationHelper'

export default class ParkReservationDetails extends Component {
  state = { tickets: { }, reserving: false }

  render() {
    let { park, base, subroute, date, time, service, serviceId, slot, reserve } = this.props
    let { tickets, reserving } = this.state

    let renderCardRequired = () =>
      <div className="park-reservation-details__card-required">
        <p className='alert alert-danger'>
          You need to set up a credit card to perform a reservation. <br />
          <Link to="/settings/payment">Add a Credit Card</Link>
        </p>
      </div>

    let doReserve = () => {
      this.setState({reserving: true})
      reserve(slot, {
        tickets, serviceId,
        success: () => {
          this.setState({reserving: false})
          App.go(App.router.parkReservation(base, park, service, date))
        }
      })
    }

    let inc = (ticketTypeName, count) => {
      this.setState({ tickets: { ...tickets, [ticketTypeName]: (tickets[ticketTypeName] || 0) + count } })
    }

    if (park.mayRequirePayment && !application.user.hasCard)
      return renderCardRequired()

    let price = service ? service.amount : park.reservation_price
    let duration = service ? service.duration : null

    return (
      <div className="park-reservation-details">
        <h3 className="park-reservation-details__header">Reservation Details</h3>
        <div className="park-reservation-details__item">
          Date: {Formatter.dateUS(slot.date)} {Formatter.hhmmToAmpm(slot.time)}
        </div>
        {
          duration &&
            <div className="park-reservation-details__item">
              Duration: {Formatter.duration(duration)}
            </div>
        }
        {
          park.reservation_options &&
            <div className="park-reservation-details__options">
              <table className="table">
                <tbody>
                {
                  park.reservation_options.map(option =>
                    <tr key={option.type}>
                      <td>{option.type}</td>
                      <td>{Formatter.amountFromCents(option.amount)}</td>
                      <td className="text-right">
                        <span className='counter'>{tickets[option.type] = 1}</span>
                      </td>
                    </tr>
                  )
                }
                </tbody>
              </table>
            </div>
        }
        <div className="park-reservation-details__actions">
          <SmartButtonLink className='btn-sm' to={App.router.parkReservation(base, park, service, date)} text="Cancel" />
          <SmartButtonStub className='btn-sm' text="Reserve" click={doReserve} spinning={reserving} />
        </div>
      </div>
    )
  }
}

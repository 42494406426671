import LikeImage         from 'images/like.png'
import LikeEmptyImage    from 'images/like-empty.png'
import PublishImage      from 'images/publish.png'
import PublishEmptyImage from 'images/publish-empty.png'
import { Modal, withErrorBoundary, connected, GoogleStaticMap, Formatter } from 'shared'
import { SmartButton } from 'ui/smart-buttons'
import { eventHours, eventDate, eventDateTitle} from './EventHelper'
import OccurrenceRow             from './OccurrenceRow'
import EventReservationBlock     from './EventReservationBlock'
import { MissingImage } from 'customizations'

class EventModal extends Component {
  componentDidMount() {
    let { event } = this.props
    if (event && event.isEvent) event.track()
    if (application.canNestChat) App.state.setObject({ base: application.path })
  }

  render() {
    let { event } = this.props

    return (
      <div className="EventModal custom-modal">
        {this.renderHead()}
        {this.renderBody()}
      </div>
    )
  }

  renderHead() {
    let { event } = this.props
    return (
      <div className="modal-head">
        <img className="modal-image" src={event.image_url || MissingImage} />
        <img className="modal-image-preview" src={event.image_preview_url || MissingImage} />
        <div className="modal-titlebar">
          <h3 className="modal-title">{event.name}</h3>
          {event.isEventlike && App.user.regular &&
            <div className="modal-titlebar-actions">
              <span className='modal-titlebar-action' onClick={() => event.like()}>
                <img src={event.isLiked ? LikeEmptyImage : LikeImage} />
                <span className="text">{event.view_count}</span>
              </span>
              {event.isRegular &&
                <span className='modal-titlebar-action' onClick={() => event.publish()}>
                  <img src={event.isPublished ? PublishEmptyImage : PublishImage} />
                  <span className="text">Push to Followers</span>
                </span>
              }
            </div>
          }
        </div>
        <Modal.CloseButton />
      </div>
    )
  }

  renderBody() {
    let { event, smartButtons, reloadSmartButtons, base, subroute } = this.props
    return (
      <div className="modal-body">

        <div className="left-pane">
          <div className="entity-date" title={eventDateTitle(event)}>{eventDate(event, 'longest')}</div>
          <div className="entity-time" title={eventDateTitle(event)}>{eventHours(event)}</div>
          {event.showMap ? (
            <>
              <div className="entity-map">
                <GoogleStaticMap defaultZoom={15} defaultCenter={event.coords} address={event.address} />
              </div>
              <div className="left-pane-text-content">
                <div className="entity-address">
                  <a href={event.address_map_url} className="text-ligher" target="_blank">{event.address}</a>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>

        <div className="right-pane">
          {
            (subroute || '').startsWith('reserve') ?
              <EventReservationBlock {...{ event, base, subroute, reloadSmartButtons }} /> :
              this.renderRightPane()
          }
        </div>

      </div>
    )
  }

  renderRightPane() {
    let { event } = this.props
    return (
      <div className='event-base-info'>
        {event.isEnrollmentPending &&
          <div className="alert alert-warning text-center">
            Enrollment request submitted
          </div>
        }
        <div className="entity-description">
          {event.note && <p>{event.note}</p>}
          <div dangerouslySetInnerHTML={{__html: event.description}}></div>
        </div>
        {event.occurrences && this.renderOccurrences()}
        {(event.isEventlike || event.isParkReservation) && this.renderSmartButtons()}
      </div>
    )
  }

  renderOccurrences() {
    let { event, base } = this.props
    return (
      <div className="occurrence-row-list">
        {event.hasOccurrences ?
          <table className={classNames("occurrence-row-table", {'is-multiday': event.isMultiday})}>
            <tbody>
              {event.occurrenceObjects.map(occurrence =>
                <OccurrenceRow key={occurrence.id} {...{event, occurrence, base} } />
              )}
            </tbody>
          </table>
        :
          <div>Will not occurr any time soon.</div>
        }
      </div>
    )
  }

  renderSmartButtons() {
    let { event, smartButtons, reloadSmartButtons, base } = this.props

    return (
      <div className="entity-smart-buttons smart-buttons">
        {smartButtons && smartButtons.map(smartButton =>
          <SmartButton {...{smartButton, reloadSmartButtons, base}} object={event} key={smartButton.key} returnPath={application.path} />
        )}
      </div>
    )
  }


}

export default SmartButton.containerComponent('event', withErrorBoundary(EventModal))

import { withErrorBoundary } from 'shared'
import { Spinner }           from 'shared'
import { FAIcon }            from 'shared'
import { NoResults }         from 'shared'
import { Backend }           from 'base'
import { TopLevelPage }      from 'ui'
import PaymentCardItem       from './PaymentCardItem'

class PaymentCardList extends Component {
  state = { }

  componentDidMount() {
    this.loadData()
  }

  async loadData() {
    let { cards } = await Backend.get('/profile/credit_cards')
    if (cards.length == 0) localStorage.rlHasCard = false
    this.cardsDidUpdate(cards)
  }

  render() {
    let { cards } = this.state
    return (
      <TopLevelPage className="SquarePayment">
        <div className="credit-cards-list">
          {
            cards == null ? <Spinner /> :
            cards.length == 0 ? <NoResults>No Credit Cards Saved</NoResults> :
            cards.map(card => <PaymentCardItem key={card.id} card={card} cardsDidUpdate={this.cardsDidUpdate} />)
          }
        </div>
        <div className="credit-cards-list text-right">
          <Link to="/settings/payment/new" className='btn smart-button'>
            <FAIcon icon='faPlus' /> Add New Card
          </Link>
        </div>
      </TopLevelPage>
    )
  }

  cardsDidUpdate = (cards) => {
    this.setState({ cards })
  }
}

export default withErrorBoundary(PaymentCardList)

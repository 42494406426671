import { Modal, withErrorBoundary, Formatter } from 'shared'
import { PaymentApi } from 'api'
import { SmartButton } from 'ui/smart-buttons'

class PaymentModal extends Component {
  render() {
    let { base } = this.props
    let activePaymentButton = application.state.getObject('activePaymentButton')
    let paymentFor = application.state.getObject('paymentFor')
    let onClose = () => { application.state.deleteObject('activeModalType', 'activePaymentButton', 'paymentFor') }
    return (
      <Modal title='Payment' opened onClose={onClose} size='normal'>
        <PaymentModalInner smartButton={activePaymentButton} paymentFor={paymentFor} base={base} onClose={onClose} />
      </Modal>
    )
  }
}

class PaymentModalInner extends Component {
  state = { }

  render() {
    let { smartButton, base = '/', onClose } = this.props
    let { progress, done, succeeded, failed, error } = this.state
    let amount = Formatter.amountFromCents(smartButton.amount)
    let hasCard = application.user.hasCard

    if (!hasCard) {
      return <PaymentModalChrome>
        <p>You need to set up a credit card to perform payments.</p>
        <Link to="/settings/payment" onClick={this.didMovedToCardSettings}>Add a Credit Card</Link>
      </PaymentModalChrome>
    }

    return (
      <PaymentModalChrome>
        {!progress && !done &&
          <p>
            <button className='btn btn-primary' type="button" onClick={this.pay}>Pay {amount}</button>
          </p>
        }
        {progress &&
          <p className="alert alert-info text-center">Processing...</p>
        }
        {succeeded &&
          <div>
            <p className="alert alert-info text-center">Payment completed. Thank you.</p>
            <p>
              <button className='btn btn-primary' type="button" onClick={this.done}>Done</button>
            </p>
          </div>
        }
        {failed &&
          <div>
            <p className="alert alert-danger text-center">Payment failed. {error}</p>
            <p>
              <button className='btn btn-primary' type="button" onClick={this.done}>Done</button>
            </p>
          </div>
        }
      </PaymentModalChrome>
    )
  }

  pay = async () => {
    let { smartButton, paymentFor } = this.props
    this.setState({ progress: true })
    let paymentParams = {}
    if(paymentFor)
      paymentParams[paymentFor.split(":")[0]] = paymentFor.split(':')[1]
    let result = await PaymentApi.perform(smartButton, paymentParams)
    if (result.succeeded)
      this.setState({ done: true, progress: false, succeeded: true })
    else
      this.setState({ done: true, progress: false, failed: true, error: result.error_message })
  }

  done = () => { this.props.onClose() }
  didMovedToCardSettings = () => { Modal.current.hide() }
}

export default withErrorBoundary(PaymentModal)

const PaymentModalChrome = ({ children }) =>
  <div className="PaymentModal">
    <div className="modal-body">
      <div className="PaymentModal-content text-center">
        <h2 className='PaymentModal-heading'>Perform Payment</h2>
        { children }
      </div>
    </div>
  </div>

import { GradientedScroller, connected, withErrorBoundary, NoResults} from 'shared'
import { TopLevelPage, ModelGrid } from 'ui'
import SlideEventCard from 'ui/events/SlideEventCard'
import PeopleCard from 'ui/people/PeopleCard'
import { DateRangePicker } from 'react-date-range';
import { addDays, format} from 'date-fns';
import { useEffect, useState } from 'react';
import { LogoImage } from 'customizations';

intervalId = null

GuestSecretCalenderView = ({match}) ->
  feedItemId = match.params.id
  [slides, setSlides] = useState([])
  [sliderTime, setSliderTime] = useState("5000")
  [refreshTime, setRefreshTime] = useState(2000)
  [initialized, setInitialized] = useState(false)
  [userCounter, setUserCounter] = useState()
  [eventCounter, setEventCounter] = useState()

  loadSlideData = () =>
    Backend.get('/slides', {user_set_counter: userCounter, event_counter: eventCounter}).then (data) =>
      setSlides(data.slides)
      setSliderTime(data.slider_time)
      setRefreshTime(if data.refresh_time then data.refresh_time else 3600000)
      setUserCounter(if data.user_set_counter then data.user_set_counter else 0)
      setEventCounter(data.event_counter)

  useEffect(() =>
    console.log("Inside useEffect")
    unless initialized
      loadSlideData().then =>
        setInitialized true

    clearInterval(intervalId)  if intervalId
    intervalId = setInterval(() =>
      loadSlideData()
    , refreshTime )
    return # Don't remove this line
  , [initialized, refreshTime, userCounter, eventCounter])

  renderNavbar = ->
    <>
      <div className="slides-navbar">
        <nav className="navbar top navbar-light">
          <NavLink className="slide-navbar-brand" to="/home">
            <img className='slide-navbar-logo' src={LogoImage} />
          </NavLink>
        </nav>
      </div>
    </>

  renderTomorrowWidget = () ->
    <div className="tomorrow"
      data-location-id="112334"
      data-language="EN"
      data-unit-system="IMPERIAL"
      data-skin="dark"
      data-widget-type="upcoming"
      style={{ paddingBottom: '10px', position: 'relative' }}
    >
      <a href="https://www.tomorrow.io/weather-api/"
        rel="nofollow noopener noreferrer"
        target="_blank"
        style={{ position: 'absolute', bottom: '0', transform: 'translateX(-50%)', left: '50%' }}
      >
      </a>
    </div>

  <TopLevelPage className="Calendar is-diabled slides-padding" fullWidth={true} modelId={feedItemId} base="/community-calendar">
    <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
      <div className="carousel-inner">
        {if slides?
          slides.map((slide, index) => (
            <div key={index} className={if index == 0 then "carousel-item active" else "carousel-item"} data-interval={slide.timer*1000}>
              {if slide.type is 'image'
                fileExtension = slide.data.split('.').pop().toLowerCase()
                <div className="slides-image-top-padding">
                  {if fileExtension == 'png'
                    <img src={slide.data} style={{ width: '100%', height: '720px'}}/>
                  else if fileExtension == 'mp4'
                    <div style={{ width: '100%', maxWidth: '100vw', overflow: 'hidden' }}>
                      <video
                        controls
                        autoPlay
                        muted
                        preload='true'
                        style={{ width: '100%', height: '720px', objectFit: 'fill' }}
                        poster={slide.data}
                        loop="loop"
                      >
                        <source src={slide.data} type="video/mp4" />
                      </video>
                    </div>
                  }
                </div>
              else if slide.type is 'web'
                <div className="slides-image-top-padding">
                  <iframe src={slide.file_path} width="100%" height="700px" scrolling="no" style={{  border: 'none', overflow: 'hidden' }} />
                </div>
              else if slide.type is 'checkin'
                <div>
                  { renderNavbar() }
                  {if slide.weather_banner? and slide.weather_banner is "1"
                    renderTomorrowWidget()
                  }
                  <div className="row justify-content-center">
                    <div className="col-md-8">
                      <h6 className="slides-heading-lowercase" style={{ fontSize: '20px', important: 'true' }}>{slide.data.title}</h6>
                    </div>
                  </div>
                  <GradientedScroller containerClassName="feed-columns row">
                    {
                      slide.users.map((model) => (
                        <section className="feed-column col-sm-6 col-lg-4 col-xl-3" key={model.uid}>
                          <div className="feed-column-body">
                            <PeopleCard user={model} key={model.id} base='/large-screen' />
                          </div>
                        </section>
                      ))
                    }
                  </GradientedScroller>
                </div>
              else if slide.type is 'event'
                <div>
                  { renderNavbar() }
                  {if slide.weather_banner? and slide.weather_banner is "1"
                    renderTomorrowWidget()
                  }
                  {if slide.data.title?
                    <div className="row justify-content-center">
                      <div className="col-md-8">
                        <h6 className="slides-heading-lowercase" style={{ fontSize: '20px', important: 'true' }}>{slide.data.title}</h6>
                      </div>
                    </div>
                  else
                    <div className="centered-title"></div>
                  }
                  {if (slide.data.events.length == 0)
                    <NoResults>We’re sorry, this section contains no events.</NoResults>
                  else
                    <GradientedScroller containerClassName="feed-columns row">
                    {
                      slide.data.events.map((model) => (
                        <section className="feed-column col-sm-6 col-lg-4 col-xl-3" key={model.uid}>
                          <div className="feed-column-body">
                            <SlideEventCard event={model} key={model.id} base='/large-screen' />
                          </div>
                        </section>
                      ))
                    }
                  </GradientedScroller>
                  }
                </div>
              else
                <div key="noSlides">No slides available</div>
              }
            </div>
          ))
        else
          <div key="noSlides">No slides available</div>
        }
      </div>
    </div>

  </TopLevelPage>

export default withErrorBoundary connected GuestSecretCalenderView
